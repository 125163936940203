//import { format } from "date-fns";
import { useState } from 'react';
import { default as uniqid } from 'uniqid';
import PaypalCheckoutButton from './PaypalCheckoutButton';
import BinanceCheckoutButton from "./BinanceCheckoutButton";
import SendTicket from './SendTicket';

const FlightsForm = ({ flights, finished, finishedSent }) => {
    const [flight, setFlight] = useState(null);
    const [email, setEmail] = useState('');
    const [travelerTitle, setTravelerTitle] = useState('');
    const [travelerFirstName, setTravelerFirstName] = useState('');
    const [travelerLastName, setTravelerLastName] = useState('');
    const [travelerType, setTravelerType] = useState('');
    const [travelerEmail, setTravelerEmail] = useState('');
    const [showSendTicket, setShowSendTicket] = useState(false);

    let flightsArray = [];
    if (flights != null) {
        flightsArray = flights;
    }

    const handleSetFlight = (flight) => {
        setFlight(flight);
    }

    const handleSetEmail = (email) => {
        setEmail(email);
    }

    const handleShowSendTicket = (show) => {
        setShowSendTicket(show);
        if (!show) {
            finished();
        }
    }

    const handleFinishedSent = () => {
        finishedSent();
    }

    const handleSetTravelerTitle = (title) => {
        setTravelerTitle(title);
    }

    const handleSetTravelerFirstName = (name) => {
        setTravelerFirstName(name);
    }

    const handleSetTravelerLastName = (name) => {
        setTravelerLastName(name);
    }

    const handleSetTravelerType = (type) => {
        setTravelerType(type);
    }

    const handleSetTravelerEmail = (email) => {
        setTravelerEmail(email);
    }

    return (
        <>
            {showSendTicket &&
                <SendTicket email={ email } flight={ flight } tTitle={ travelerTitle } tFirstName={ travelerFirstName } tLastName={ travelerLastName } tType={ travelerType } tEmail={ travelerEmail }  finished={ handleShowSendTicket } finishedSent={ handleFinishedSent } />
            }
            <div className="FlightsFormContainer" id="FlightContainer">
                {
                    flightsArray.length > 0 && (
                    <>
                    {flightsArray.map((data, index) => (
                        <FlightBlock flight={data} key={index} setTravelerEmail={handleSetTravelerEmail} setTitle={handleSetTravelerTitle} setFirstName={handleSetTravelerFirstName} setLastName={handleSetTravelerLastName} setType={handleSetTravelerType} setFlight={ handleSetFlight } setEmail = { handleSetEmail } showSendTicket={ handleShowSendTicket } />
                    ))}
                    </>
                )}
            </div>
        </>
    );
}

function FlightBlock({ flight, setTravelerEmail, setTitle, setFirstName, setLastName, setType, setFlight, setEmail, showSendTicket }) {
    const [travFirstName, setTravFirstName] = useState('');
    const [travLastName, setTravLastName] = useState('');
    const [travEmail, setTravEmail] = useState('');

    const [showPayment, setShowPayment] = useState(false);
    const [showPaypalContainer, setShowPaypalContainer] = useState(false);
    const [showBinanceContainer, setShowBinanceContainer] = useState(false);
    const [showTraveler, setShowTraveler] = useState(false);
    let segments = [];
    let segmentsLength = 0;
    if (flight.segments) {
        segments = flight.segments;
        segmentsLength = segments.length;
    }
    const flightAt = segments[0].departure.at;
    const flightFromCode = segments[0].departure.iata_code;
    //const flightFromAirport = segments[0].departure.airport_name;
    //const flightFromTerminal = segments[0].departure.terminal;

    const [showFlightInfo, setShowFlightInfo] = useState(false);

    const flightToCode = segments[segmentsLength - 1].arrival.iata_code;
    // const flightToAirport = segments[segmentsLength - 1].arrival.airport_name;
    //const flightToTerminal = segments[segmentsLength - 1].arrival.terminal;
    const arrivalAt = segments[segmentsLength - 1].arrival.at;
    const arrivalDate = new Date(arrivalAt);
    const arrivalDateText = arrivalDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
    const departureDate = new Date(flightAt);
    const departureDateText = departureDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
    let carriers = [];
    let breaks = [];
    const addCarrier = (key, value) => {
        if (!carriers.some(item => item.key === key)) {
            carriers.push({ key, value });
        }
    };
    for (let i = 0; i < segmentsLength; i++) {
        if (i > 0) {
            const date1 = new Date(segments[i].departure.at);
            const date2 = new Date(segments[i - 1].arrival.at);
            const timeDifferenceMs = date1 - date2;
            const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));
            let formattedTimeDifference = ``;
            if (hours > 0) {
                formattedTimeDifference += `${hours}h`;
            }
            if (minutes > 0) {
                if (hours > 0) {
                    formattedTimeDifference += ` `;
                }
                formattedTimeDifference += `${minutes}min`;
            }
            breaks.push(formattedTimeDifference);
        }
        addCarrier(segments[i].carrier, segments[i].carrier_code);
    }
    let operating = [];
    const addOperating = (key, value) => {
        if (!operating.some(item => item.key === key)) {
            operating.push({ key, value });
        }
    }
    for (let i = 0; i < segmentsLength; i++) {
        addOperating(segments[i].operating, segments[i].operating_code);
    }
    let transfers = [];
    if (segmentsLength > 1) {
        for (let i = 0; i < segmentsLength - 1; i++) {
            transfers.push(segments[i].arrival.iata_code);
        }
    }

    const viewFlightClick = (event) => {
        window.gtag('event', 'open_flight');
        setShowFlightInfo(!showFlightInfo);
        const parentDiv = document.getElementById("FlightContainer");
        const childDiv = event.target.parentNode.parentNode;
        parentDiv.scrollTop = childDiv.offsetTop;

        const parentRect = parentDiv.getBoundingClientRect();
        const childRect = childDiv.getBoundingClientRect();

        const scrollTopValue = childRect.top - parentRect.top + parentDiv.scrollTop - 10;
        parentDiv.scrollTop = scrollTopValue;
        //parentDiv.scrollTo({ top: scrollTopValue, behavior: 'smooth' });
    }

    const generateUniqueId = () => {
        return uniqid();
    };

    const handleFlightClick = (event) => {
        setFlight(flight);
        setShowTraveler(!showTraveler);
        setShowPayment(false);
        window.gtag('event', 'open_get_ticket');
    }

    const handleCheckoutClick = (event) => {
        if (travFirstName === "" || travLastName === "") {
            alert("Fill in your First Name and Last Name please");
            setShowPayment(false);
            setShowPaypalContainer(false);
            setShowBinanceContainer(false);
            return;
        }
        if (travEmail === "") {
            alert("Fill in your E-mail please");
            setShowPayment(false);
            setShowPaypalContainer(false);
            setShowBinanceContainer(false);
            return;
        }
        let travName = travFirstName + " " + travLastName
        if (travName === "Vadim Vasenkin" || travName === "Aleksandr Kalmyk") {
            showSendTicket(true);
        } else {
            window.gtag('event', 'open_checkout');
            setShowPaypalContainer(false);
            setShowBinanceContainer(false);
            setShowPayment(!showPayment);
        }
    }

    const handleTicketPayment = (payment) => {
        var email = '';
        if (payment.status === "COMPLETED") {
            if (payment.payer.email_address !== undefined && payment.payer.email_address !== '') {
                email = payment.payer.email_address;
            }

            if (email !== '') {
                setEmail(email);
            }
            setShowPayment(!showPayment);
            window.gtag('event', 'purchase');
            showSendTicket(true);
            window.gtag('event', 'send_email');
        }
    }

    const sendTravelerTitle = (title) => {
        setTitle(title)
    }

    const sendTravelerFirstName = (name) => {
        setTravFirstName(name);
        setFirstName(name);
        setShowPayment(false);
    }

    const sendTravelerLastName = (name) => {
        setTravLastName(name);
        setLastName(name);
        setShowPayment(false);
    }

    const sendTravelerType = (type) => {
        setType(type);
    }

    const sendTravelerEmail = (email) => {
        setTravEmail(email);
        setTravelerEmail(email);
    }

    const handlePaypalClick = (event) => {
        setShowBinanceContainer(false);
        setShowPaypalContainer(!showPaypalContainer);
    }

    const handleBinanceClick = (event) => {
        setShowPaypalContainer(false);
        setShowBinanceContainer(!showBinanceContainer);
    }

    return (
        <>
            <div className="FlightBlock">
                <div className="FlightBlockLeft">
                    <table className="FlightBlockTable">
                        <tbody>
                            <tr>
                                <td>
                                    <Carriers carriers={carriers} key={generateUniqueId()} />
                                    <table className="FlightInfoTable">
                                        <tbody>
                                            <tr>
                                                <td className="LeftInfoTd">
                                                    <div className="FlightDateLeft">{ departureDateText }</div>
                                                    <div className="FlightTime">{ flightAt.split("T")[1].slice(0, -3) }</div>
                                                    <div className="FlightCode">{ flightFromCode }</div>
                                                    {/*<div>{ flightFromAirport }</div>*/}
                                                </td>
                                                <td className="MidInfoTd">
                                                    <div className="SmallText">{ flight.duration.replace("PT", "").replace("H", "h ").toLowerCase() }</div>
                                                    <div className="Airplane"></div>
                                                    <div>
                                                    {
                                                        transfers.length > 0 && (
                                                            <>
                                                                <div className="StopsInfoDiv">{transfers.length} stops</div>
                                                            {transfers.map((data, index) => (
                                                                <span className="TransfersSpan" key={index}>{data}</span>
                                                            ))}
                                                            </>
                                                        )
                                                    }
                                                    </div>
                                                </td>
                                                <td className="RightInfoTd">
                                                    <div className="FlightDateRight">{ arrivalDateText }</div>
                                                    <div className="FlightTime">{ arrivalAt.split("T")[1].slice(0, -3) }</div>
                                                    <div className="FlightCode">{ flightToCode }</div>
                                                    {/*<div>{ flightToAirport }</div>*/}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                {/*
                                    !areArraysEqual(carriers, operating) && (
                                        <td colSpan="2" className="SmallText">
                                            Operated by: 
                                            <Operating operating={operating} key={generateUniqueId()} />
                                        </td>
                                    )
                                    */}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="FlightBlockRight">
                    <div className="TicketPrice">Price: <span className="OldPriceSpan">{ "$" + flight.price.total }</span> $5</div>
                    <div className="ViewFlightBtn" onClick={viewFlightClick}>{ showFlightInfo ? "Hide Flight Info" : "View Flight Info" }</div>
                </div>
            </div>
            {
                showFlightInfo && (
                    <div className="FlightInfoBlock">
                        <div className="FlightInfoBlockLeft">
                        {
                            segmentsLength > 0 && (
                                <>
                                    <div><span className="DepartArriveTextSpan">Departs:</span><span className="FlightDateLeft">{ departureDateText }</span></div>
                                    { segments.map((data,index) => (
                                        <FlightBlockTable key={ index } data={ data } breaks={ breaks } index={ index } />
                                    ))}
                                    <br />
                                    <div><span className="DepartArriveTextSpan">Arrives:</span><span className="FlightDateLeft">{ arrivalDateText }</span><span className="HorizontalSpacerSpan">|</span><span className="TripDurationTextSpan">Trip duration:</span><span className="SmallText">{ flight.duration.replace("PT", "").replace("H", "h ").toLowerCase() }</span></div>
                                </>
                            )
                        }
                        </div>
                        <div className="FlightInfoBlockRight">
                            <div className="ViewFlightBtn" onClick={handleFlightClick}>Get ticket</div>
                            {showTraveler &&
                                <TravelerForm checkoutClick={handleCheckoutClick} sendTravelerEmail={sendTravelerEmail} sendTravelerTitleUp={sendTravelerTitle} sendTravelerFirstNameUp={sendTravelerFirstName} sendTravelerLastNameUp={sendTravelerLastName} sendTravelerTypeUp={sendTravelerType} />
                            }
                            {showPayment && (
                                <div className="CheckoutContainer">
                                    <div className="PayPalCheckoutIcon" onClick={handlePaypalClick}></div>
                                    <div className="BinanceCheckoutIcon" onClick={handleBinanceClick}></div>
                                </div>
                            )}
                            {showPaypalContainer && (
                                <div className="PayPalCheckoutContainer">
                                    <PaypalCheckoutButton ticketPayment={handleTicketPayment} key={generateUniqueId()} />
                                </div>
                            )}
                            {showBinanceContainer && (
                                <div className="BinanceCheckoutContainer">
                                    <BinanceCheckoutButton ticketPayment={handleTicketPayment} travEmail={travEmail} key={generateUniqueId()} />
                                </div>
                            )}
                        </div>
                        <div className="ClearDiv"></div>
                    </div>
                )
            }
        </>
    );
}

function FlightBlockTable({ data, breaks, index }) {
    function formatDate(date) {
        const tmp_date = new Date(date);
        return tmp_date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        });
    }
    const generateUniqueId = () => {
        return uniqid();
    };

    return (
        <>    
            <table className="FlightBlockTable" style={{marginBottom: "10px"}}>
                <tbody>
                    <tr>
                        <td></td>
                        <td className="FlightInfoDataTd"><CarriersInfo carrier={data.carrier} code={data.carrier_code} number={data.number} key={generateUniqueId()} /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="FlightInfoDurationTd">
                            <div className="SmallText">{ data.duration.replace("PT", "").replace("H", "h ").toLowerCase() }</div>
                            <div className="FlightDurationTdDecoratorTop"></div>
                            <div className="FlightDurationTdDecoratorBottom"></div>
                        </td>
                        <td className="FlightInfoDataTd">
                            <span className="FlightDateLeft">{ formatDate(data.departure.at) }</span><span className="FlightTime">{ data.departure.at.split("T")[1].slice(0, -3) }</span>
                            <div>
                                <span className="FlightCodeSpan">{ data.departure.iata_code }</span>
                                <span className="FlightAirportNameSpan">{ data.departure.airport_name + " (" + data.departure.country + ")" }</span>
                            </div>
                            <div className="TdSpacer"></div>
                            <div className="TdSpacer"></div>
                            <span className="AirplaneSpan">{ data.aircraft }</span>
                            <div className="TdSpacer"></div>
                            <span className="FlightDateLeft">{ formatDate(data.arrival.at) }</span><span className="FlightTime">{ data.arrival.at.split("T")[1].slice(0, -3) }</span>
                            <div>
                                <span className="FlightCodeSpan">{data.arrival.iata_code}</span>
                                <span
                                    className="FlightAirportNameSpan">{data.arrival.airport_name + " (" + data.arrival.country + ")"}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {
                breaks[index] && (
                    <div className="FlightInfoBreak">{breaks[index] } transfer</div>
                )
            }
        </>
    );
}

function Carriers({ carriers }) {
    return (
        <>
            {carriers.map(item => {
                const { key, value } = item;
                return (
                    <div key={value}>
                        <div className="LeftCarrierImgCentered"><img alt="" title={ key + " (" + value + ")" } src={ "/icons/" + value + ".png" } /></div>
                    </div>
                );
            })}
        </>
    );
}

function TravelerForm({checkoutClick, sendTravelerEmail, sendTravelerTitleUp, sendTravelerFirstNameUp, sendTravelerLastNameUp, sendTravelerTypeUp}) {
    const handleCheckoutClick = (event) => {
        checkoutClick();
    }

    const handleChangeFirstName = (event) => {
        sendTravelerFirstNameUp(event.target.value);
    }

    const handleChangeLastName = (event) => {
        sendTravelerLastNameUp(event.target.value);
    }

    const handleTitleChange = (event) => {
        sendTravelerTitleUp(event.target.value);
    }

    const handleTypeChange = (event) => {
        sendTravelerTypeUp(event.target.value);
    }

    const handleEmailChange = (event) => {
        sendTravelerEmail(event.target.value);
    }

    return (
        <>
            <div className="TravelerDataForm">
                <table className="TravelerDataTable">
                    <tbody>
                    <tr>
                        <td>Title:</td>
                        <td>
                            <select id="title" name="title" onChange={handleTitleChange}>
                                <option value="-" defaultValue>-</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Your First Name:</td>
                        <td>
                            <input type="text" id="first_name" name="first_name" placeholder="John"
                                   onChange={handleChangeFirstName}></input>
                        </td>
                    </tr>
                    <tr>
                        <td>Your Last Name:</td>
                        <td>
                            <input type="text" id="last_name" name="last_name" placeholder="Smith"
                                   onChange={handleChangeLastName}></input>
                        </td>
                    </tr>
                    <tr>
                        <td>Ticket Type:</td>
                        <td>
                            <select id="type" name="type" onChange={handleTypeChange}>
                                <option value="Adult" defaultValue>Adult</option>
                                <option value="Child">Child</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Your Email (for ticket):</td>
                        <td>
                            <input type="text" id="email" name="email" placeholder="john_smith@mail.on"
                                   onChange={handleEmailChange}></input>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <div className="ViewFlightBtn CheckoutBtn" onClick={handleCheckoutClick}>Checkout</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

function CarriersInfo({carrier, code, number}) {
    return (
        <>
            <div className="LeftCarrierImg">
                <img alt="" title={carrier + " (" + code + ")"} src={"icons/" + code + ".png"}/>
                <div>
                    <span className="FlightAirportNameSpan">{carrier}</span>
                    <span className="FlightAirportNameSpan">{"Flight " + code + number}</span>
                </div>
            </div>
        </>
    );
}

export default FlightsForm;