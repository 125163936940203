import { GlobalContext } from '../App';
import { useContext, useState } from 'react';

const BinanceCheckoutButton = ({ticketPayment, travEmail}) => {
    const { beUrl } = useContext(GlobalContext);
    const [showCheckoutBtn, setShowCheckoutBtn] = useState(true);
    const [showCheckoutForm, setShowCheckoutForm] = useState(false);
    const [binanceOrder, setBinanceOrder] = useState({});
    const [cryptoPayBtnText, setCryptoPayBtnText] = useState('Pay with crypto (USDT) on Binance');
    const handleClick = (event) => {
        console.log("clicked crypto checkout");
        setCryptoPayBtnText("Creating Binance order...");

        let order;
        let prepayID = "";
        let checkStatusInterval;

        fetch(`${beUrl}/create_binance_order`)
            .then(response => {
                if (response.status === 200) {
                    console.log("created order on Binance");
                    return response.json();
                } else {
                    console.log("Status code:" + response.status);
                    throw new Error("Fetch failed");
                }
            })
            .then(data => {
                order = data;
                console.log(order);
                setBinanceOrder(order);
                prepayID = order.data.prepayId;
                setShowCheckoutBtn(false);
                setShowCheckoutForm(true);
            })
            .catch(error => {
                    console.error(error);
            });

        checkStatusInterval = setInterval(function() {
            fetch(`${beUrl}/status_binance_order?prepayID=${prepayID}`)
                .then(response => {
                    if (response.status === 200) {
                        return response.text();
                    } else {
                        console.log("Status code:" + response.status);
                        throw new Error("Fetch failed");
                    }
                })
                .then(data => {
                    if (data === "EXPIRED") {
                        console.log("order expired");
                        clearInterval(checkStatusInterval);
                    }
                    if (data === "CANCELED") {
                        console.log("order canceled");
                        clearInterval(checkStatusInterval);
                    }
                    if (data === "ERROR") {
                        console.log("error in order");
                        clearInterval(checkStatusInterval);
                    }
                    // if (data === "PENDING") {
                    //     console.log("order pending");
                    // }
                    if (data === "PAID") {
                        console.log("order paid");
                        clearInterval(checkStatusInterval);
                        let payment = {
                            status: "COMPLETED",
                            payer: {
                                email_address: travEmail
                            }
                        };
                        ticketPayment(payment);
                    }
                    // if (data === "INITIAL") {
                    //     console.log("order fresh");
                    // }
                })
                .catch(error => {
                    console.error(error);
                });
        }, 10000);

        // "INITIAL", "PENDING", "PAID", "CANCELED", "ERROR", "REFUNDING", "REFUNDED", "EXPIRED"
    }

    return (
        <>
            {showCheckoutBtn && (
                <div className="BinanceCheckoutButton" onClick={handleClick}>
                    <img className="CheckoutBinanceLogo" src={"img/binance_logo.png"} alt={"Binane logo"}/>
                    {cryptoPayBtnText}
                </div>
            )}
            {showCheckoutForm && Object.keys(binanceOrder).length !== 0 && (
                <div className="BinanceCheckoutForm">
                    <div className="DoNotMoveAlert">DO NOT CLOSE THIS PAGE AFTER THE PAYMENT, UNTIL THE ORDER HAS BEEN CONFIRMED!</div>
                    <div>Order ID: {binanceOrder.data.prepayId}</div>
                    <div>Order expires: {new Date(binanceOrder.data.expireTime).toLocaleString()}</div>
                    <div>Order total price: {binanceOrder.data.totalFee + " " + binanceOrder.data.currency}</div>
                    <br/>
                    <div>You can choose payment method below:</div>
                    <br />
                    <div className="BinanceCheckoutFormPaymentMethod">
                        <div className="BinanceCheckoutFormPaymentMethodContent">
                            <img src={binanceOrder.data.qrcodeLink} alt={"Binance payment QR code"}/>
                            <br/><a href={binanceOrder.data.qrcodeLink} target={"_blank"} rel="noreferrer">QR code</a>
                        </div>
                    </div>
                    <div className="BinanceCheckoutFormPaymentMethod">
                        <div className="BinanceCheckoutFormPaymentMethodContent">
                            <a href={binanceOrder.data.deeplink} target={"_blank"} rel="noreferrer">
                                <img className="BinanceMobileAppIcon" src={"img/binance_app.png"} alt={"Binance mobile app logo"}/>
                            </a>
                            <br/><a href={binanceOrder.data.deeplink} target={"_blank"} rel="noreferrer">Binance app</a>
                        </div>
                    </div>
                    <div className="BinanceCheckoutFormPaymentMethod">
                        <div className="BinanceCheckoutFormPaymentMethodContent">
                            <a href={binanceOrder.data.universalUrl} target={"_blank"} rel="noreferrer">
                                <img className="BinanceWebIcon" src={"img/binance_tmp.jpg"} alt={"Binance website logo"}/>
                            </a>
                            <br/><a href={binanceOrder.data.universalUrl} target={"_blank"} rel="noreferrer">Binance Web</a>
                        </div>
                    </div>
                    <div className="ClearDiv"></div>
                    <br />
                    <div>The order's status is checked automatically. Wait for payment confirmation please.</div>
                </div>
            )}
        </>
    );
}

export default BinanceCheckoutButton;