import { React, useContext } from 'react';
import { GlobalContext } from './App'; 

const Terms = () => {
    const { siteUrl } = useContext(GlobalContext);

  return (
    <div>
        <div className="Header">
        <h1><a className="BlankLink" href={siteUrl}>Fake Flight Tickets 🛩️</a></h1>
        </div>
        <div className="MainContent">
            <div className="GetYourTicketDiv">
            Terms & Conditions
            </div>
            <div>
                <p><u>Fake Flight Tickets</u> does not assume any responsibility or liability towards individuals or organizations in connection with our products and services.
                We disclaim any accountability for the consequences arising from the use of our information on any of our websites and properties.</p>
                <p>
                We want to let you know that we do not offer refunds, invoices, or receipts for any transactions.
                </p>
                <p>
                Your privacy is of utmost importance to us. We assure you that we do not engage in sale of your data, and we abstain from using 
                marketing or re-marketing pixels. The utilization of your name, origin, and destination for generating your onward ticket is solely 
                for this purpose. Additionally, we utilize your email address exclusively for sending you a confirmation email.
                </p>
                <p>
                By using the website, you commit to upholding and preserving the confidentiality of all security features associated with its use, 
                including but not limited to passwords and access arrangements. <u>Fake Flight Tickets</u> holds no liability for any unauthorized breach or
                disclosure of these security features.
                </p>
                <p>
                When providing feedback, such as identifying errors or issues in the operations of <u>Fake Flight Tickets</u> and its services, through any
                medium like the feedback form, email, or any other means, you acknowledge and consent that all feedback becomes the sole and 
                exclusive property of <u>Fake Flight Tickets</u>. By this, you hereby transfer and agree to transfer to <u>Fake Flight Tickets</u> all of your rights and
                interests in and to all feedback, including all intellectual property rights associated with it.
                </p>
                <p>
                You consent to indemnify <u>Fake Flight Tickets</u>, including its employees and agents, against any loss or liability incurred as a result of
                unlawful, unauthorized, or improper access or use of the website, or any violation of these terms by you, your employees, 
                contractors, or representatives.
                </p>
                <p>
                <u>Fake Flight Tickets</u> cannot guarantee continuous availability of website access and accepts no responsibility for downtime or access
                failure caused by circumstances beyond its reasonable control, including any failures by internet service providers (ISP) or 
                system providers.
                </p>
                <p>
                The site may include links to other websites on the internet, referred to as "Linked Sites". <u>Fake Flight Tickets</u> is not accountable for
                the accuracy, legality, decency of material, or copyright compliance of any Linked Site, nor for any services or information 
                provided through any Linked Site.
                </p>
                <p>
                It's crucial to understand that complete security of data transmission over the internet cannot be guaranteed. While we make 
                every effort to protect such information, we cannot assure or ensure the security of the information you transmit to us. Therefore, 
                any information you send is done so at your own risk.
                </p>
                <p>
                These terms and conditions will be interpreted in accordance with and governed by the laws of the European Union, irrespective of 
                the location where <u>Fake Flight Tickets</u> operates or where you access and use <u>Fake Flight Tickets</u>.
                </p>
            </div>
        </div>
        <div className="Footer">
            <div className="InfoText">
                <i><u>Keep in mind</u></i>, this is not a real ticket - it just looks like one.
            </div>
            <a href={siteUrl}><u>Fake Flight Tickets</u></a>
            <a href={siteUrl + "/terms"}><u>Terms & Privacy Policy</u></a>
            <a href={siteUrl + "/faq"}><u>FAQ</u></a>
            <a href={"mailto:support@fakeflighttickets.net"}><u>Contact Us</u></a>
        </div>
    </div>
  );
};

export default Terms;
