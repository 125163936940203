import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MyDatePicker = ({ dateChanger, refer }) => {
  const today = new Date();

  const [selectedDate, setSelectedDate] = useState(today.setHours(0));

  useEffect(() => {
    dateChanger(selectedDate);
  }, [selectedDate, dateChanger]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const customInput = (
    <input
      value={selectedDate ? format(selectedDate, 'dd/MM/yyyy') : 'Select a date'}
      onChange={() => {}}
      placeholder="Select a date"
    />
  );

  return (
    <div className="datepickerContainer">
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        customInput={customInput}
        showYearDropdown
        dateFormat="dd/MM/yyyy"
        yearDropdownItemNumber={1}
        minDate={today}
        className="searchInput calendarHack"
      />
    </div>
  );
};

export default MyDatePicker;
