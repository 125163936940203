import CityInput from './components/CityInput';
import CitySearchResult from './components/CitySearchResult';
import {useContext, useEffect, useRef, useState} from 'react';
import FlightDatePicker from './components/DatePicker';
import FlightsForm from './components/FlightsForm';
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {GlobalContext} from './App';

const initialOptions = {
    clientId: "AdiMBcOzQBq2tn1aDhQ35cN-8cGJ6xgLz84FHzemyOG__S6ySO9JsniMh9bcIw4AGrMqKOh9BbjVhARa",
    currency: "EUR",
    intent: "capture",
};
  
function EmailSentMessage() {
    return (
    <div className="EmailSentAlert">
        <strong>Ticket sent by E-mail, check your inbox</strong>
    </div>
    );
}

const Home = () => {
    const { siteUrl, beUrl } = useContext(GlobalContext);
    const [cityDataFrom, setCityDataFrom] = useState(null);
  const [cityDataTo, setCityDataTo] = useState(null);
  const [codeFrom, setCodeFrom] = useState('');
  const [codeTo, setCodeTo] = useState('');
  const [cityFrom, setCityFrom] = useState('');
  const [cityTo, setCityTo] = useState('');
  const [showFrom, setShowFrom] = useState(false);
  const [showTo, setShowTo] = useState(false);
  const [showSentAlert, setShowSentAlert] = useState(false);

  const today = new Date();
  today.setHours(today.getHours() + 2);
  const dateString = today.toISOString();

  const [dateDepart, setDateDepart] = useState(dateString);
  const [showFlights, setShowFlights] = useState(false);
  const [flights, setFlights] = useState(null);

  const [showLoader, setShowLoader] = useState(false);

  const [showLoaderText, setShowLoaderText] = useState(true);

  const fromRef = useRef(null);
  const toRef = useRef(null);

  useEffect(() => {
    fromRef.current.focus();

    const postMetrics = async () => {
	  try {
		const response = await fetch(beUrl + '/setmetric', {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json'
		  },
		  body: JSON.stringify({
			metric: "visit"
		  })
		});
	  } catch (error) {
		console.error('Ошибка:', error);
	  }
	};

	postMetrics();
  }, [beUrl]);

  const handleCityDataFrom = (data) => {
    setCityDataFrom(data);
    if (data != null) {
      setShowFrom(true);
    } else {
      setShowFrom(false);
    }
  };
  const handleCityDataTo = (data) => {
    setCityDataTo(data);
    if (data != null) {
      setShowTo(true);
    } else {
      setShowTo(false);
    }
  };
  const handleSetCodeFrom = code => {
    setCodeFrom(code);
    setShowFrom(false);
    toRef.current.focus();
  }
  const handleSetCodeTo = code => {
    setCodeTo(code);
    setShowTo(false);
  }
  const handleSetCityFrom = city => {
    setCityFrom(city);
    if (city === '') {
      setShowFrom(false);
      setShowFlights(false);
      setShowLoaderText(true);
    }
  }
  const handleSetCityTo = city => {
    setCityTo(city);
    if (city === '') {
      setShowTo(false);
      setShowFlights(false);
      setShowLoaderText(true);
    }
  }
  function fromDateToString(date/*: Date*/)/* : String*/ {
    date = new Date(+date);
    date.setTime(date.getTime() - (date.getTimezoneOffset() * 60000));
    return date.toISOString().substr(0, 19);
  }

  const handleDateChange = date => {
    setDateDepart(date);
  }
  const handleSearch = event => {
    const apiUrl = beUrl + '/get_ticket';
    const from = codeFrom;
    const to = codeTo;
    const dateObj = new Date(dateDepart);
    const dateStr = fromDateToString(dateObj);
    const date = dateStr.split("T")[0];
    const dt = `${date} 00:00:00`;


    if (from === "" || to === "") {
      return
    }

    setShowFlights(false);
    setShowLoaderText(false);
    setShowLoader(true);

    window.gtag('event', 'search_flights');

    fetch(`${apiUrl}?from=${from}&to=${to}&dt=${dt}`)
      .then(response => response.json())
      .then(data => {
        setFlights(data);
        if (data.flights.length > 0) {
          setShowLoader(false);
          setShowFlights(true);
        } else {
          setShowLoader(false);
          setShowLoaderText(true);
          setShowFlights(false);
        }
      })
      .catch(error => {
        console.error(error);
      }
    );
  }

  const handleFinished = () => {
    setShowFlights(false);
    setCityFrom('');
    setCityTo('');
    setShowLoader(true);
  }

  const handleFinishedSent = () => {
    setShowLoader(false);
    setShowLoaderText(true);
    setShowSentAlert(true);
    setTimeout(function() { setShowSentAlert(false); }, 5000);
  }

  return (
      <PayPalScriptProvider options={initialOptions}>
          {showSentAlert &&
              <EmailSentMessage/>
          }
          <div className="Header">
              <h1><a className="BlankLink" href={siteUrl}>Fake Flight Tickets 🛩️</a></h1>
          </div>
          <div className="MainContent">
              <div className="GetYourTicketDiv">
                  Get your fake flight ticket here
              </div>
              <div className="InfoTableContainer">
                  <a href="ticket_sample.jpg" target="_blank"><img className="MainInfoImage" src="ticket_sample.jpg" alt="Ticket sample"/></a>
                  <p className="MainInfoText">
                      Numerous countries insist on having <i>Proof of Onward Travel</i> as a prerequisite for boarding an airplane.
                      If you wish to preserve the flexibility of one-way tickets, this hack is what you need! You can even use this for
                      pranks and anything you like. Just remember - don’t use it for real flights.
                      <br/><br/>
                      This tool provides <strong>real</strong> flight data & <strong>real</strong> ticket template.
                      Please read FAQ before using this generator❗️❗️❗️
                  </p>
                  <div className="ClearDiv"></div>
              </div>
              <div className="SearchForm">
                  {/* <div className="RoundTypeDiv">
            <label className="RoundTypeLabel">
              <input type="radio" name="roundType" value="round" /> Round Trip
            </label>
            <label className="RoundTypeLabel">
              <input type="radio" name="roundType" value="one" /> One-way
            </label>
          </div> */}
                  <div className="fromCityDiv">
                      From:
                      <CityInput refer={fromRef} type="from" sendDataUp={handleCityDataFrom} currentVal={cityFrom} sendCityUp={handleSetCityFrom}/>
                      {showFrom &&
                          <CitySearchResult cityData={cityDataFrom} codeSetter={handleSetCodeFrom} citySetter={handleSetCityFrom}/>
                      }
                  </div>
                  <div className="toCityDiv">
                      To:
                      <CityInput refer={toRef} type="to" sendDataUp={handleCityDataTo} currentVal={cityTo} sendCityUp={handleSetCityTo}/>
                      {showTo &&
                          <CitySearchResult cityData={cityDataTo} codeSetter={handleSetCodeTo} citySetter={handleSetCityTo}/>
                      }
                  </div>
                  <div className="datePickerDiv">
                      Date:
                      <FlightDatePicker dateChanger={handleDateChange}/>
                  </div>
                  <div className="searchSubmitDiv">
                      <input type="submit" className="SearchBtn searchInput" value="Search My Flight" onClick={handleSearch}/>
                  </div>
                  <div className="ClearDiv"></div>
              </div>
              <div className="FlightsInfoDiv">
                  All major airlines are supported (1000+ companies).
                  <br/>Flight information (time, flight number, price, etc) is <strong>legit</strong>.
              </div>
              <div className="FlightsResultContainer">
                  {showLoaderText &&
                      <LoaderText/>
                  }
                  {showLoader &&
                      <Loader/>
                  }
                  {showFlights &&
                      <FlightsForm flights={flights.flights} finished={handleFinished} finishedSent={handleFinishedSent}/>
                  }
              </div>
              <div className="Footer">
                  <div className="InfoText">
                      <i><u>Keep in mind</u></i>, this is not a real ticket - it just looks like one.
                  </div>
                  <a href={siteUrl}><u>Fake Flight Tickets</u></a>
                  <a href={siteUrl + "/terms"}><u>Terms & Privacy Policy</u></a>
                  <a href={siteUrl + "/faq"}><u>FAQ</u></a>
                  <a href={"mailto:support@fakeflighttickets.net"}><u>Contact Us</u></a>
              </div>
          </div>
      </PayPalScriptProvider>
  );
}

function Loader() {
    return (
        <>
            <div className="Ring">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </>
    );
}

function LoaderText() {
    return (
        <>
            <div className="LoaderText">
                Choose your Departure and Destination airports above 👆<br/>Flights will appear here
            </div>
        </>
    )
}

export default Home;