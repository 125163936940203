import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createContext, useState } from 'react';
import Home from './Home';
import FAQ from './FAQ';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';

export const GlobalContext = createContext();

const App = () => {
  const [siteUrl, setSiteUrl] = useState('https://fakeflighttickets.net');
  const [beUrl, setBeUrl] = useState('https://fakeflighttickets.net:445')

  // const [siteUrl, setSiteUrl] = useState('http://localhost:3000');
  // const [beUrl, setBeUrl] = useState('http://localhost:8080')

  return (
    <GlobalContext.Provider value={{ siteUrl, setSiteUrl, beUrl, setBeUrl }}>
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </Router>
    </GlobalContext.Provider>
  );
}

export default App;
