import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
        <div className="Header">
            <h1><a className="BlankLink" href="http://localhost:3000">Fake Flight Tickets 🛩️</a></h1>
        </div>
        <div className="MainContent">
            <div className="GetYourTicketDiv">
            Privacy Policy
            </div>
            <div>
            Your privacy is of utmost importance to us. We assure you that we do not engage in the sale of your data, and we abstain from using marketing or re-marketing pixels. The utilization of your name, origin, and destination for generating your onward ticket is solely for this purpose. Additionally, we utilize your email address exclusively for sending you a confirmation email.
            </div>
        </div>
        <div className="Footer">
            <div className="InfoText">
                <i><u>Keep in mind</u></i>, this is not a real ticket - it just looks like one.
            </div>
            <a href="http://localhost:3000">Fake Flight Tickets</a>
            <a href="http://localhost:3000/terms">Terms & Conditions</a>
            <a href="http://localhost:3000/faq">FAQ</a>
        </div>
    </div>
  );
};

export default PrivacyPolicy;
