import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../App'; 

function CityInput({ refer, type, sendDataUp, currentVal, sendCityUp }) {
    const { beUrl } = useContext(GlobalContext);

    const [inputValue, setInputValue] = useState('');
    const handleChange = event => {
        setInputValue(event.target.value);
        sendCityUp(event.target.value);
    }
    const handleReset = event => {
        sendCityUp('');
    }
    const fetchData = async () => {
        try {
            if (inputValue.length >= 3) {
                const response = await fetch(beUrl + `/get_airports?mun=${encodeURIComponent(inputValue)}`, {credentials: "include",});
                const data = await response.json();
                
                sendDataUp(data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        if (inputValue.length >= 3) {
            fetchData();
        }
    }, [inputValue.length]);

    return (
        <>
            <div className="CityInputDiv">
                <input ref={ refer } type="text" autoComplete="off" id={ "CityInput" + type } className="CityInput searchInput" value={currentVal} placeholder="City/airport" onChange={handleChange} />
                {currentVal.length > 0 && 
                    <div className="CityInputReset" onClick={handleReset}></div>
                }
            </div>
        </>
    );
}

export default CityInput;