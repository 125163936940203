function CitySearchResult({ cityData, codeSetter, citySetter }) {
    let dataArray = [];
    if (cityData != null) {
        dataArray = cityData;
    }

    const handleClick = event => {
        codeSetter(event.target.getAttribute('attr-code'));
        handleCitySet(event.target.getAttribute('attr-name'));
    }
    const handleCitySet = city => {
        citySetter(city);
    }

    return (
        <>
            <div className="SearchResultDiv">
                {
                    dataArray.length > 0 && (
                    <>
                    {dataArray.map((data, index) => (
                        <CitySearchResultCityBlock cityData={data} key={index} clickHandler={handleClick} />
                    ))}
                    </>
                )}
            </div>
        </>
    );
}

function CitySearchResultCityBlock({ cityData, clickHandler, cityHandler }) {
    return (
        <>
            <div className="CitySearchResultDiv" attr-code={ cityData.city_code } attr-name={ cityData.city + ' (' + cityData.city_code + ')'} onClick={clickHandler}>{ cityData.city } ({ cityData.city_code })</div>
            <div>
            {
                cityData.airports.length > 0 && (
                <>
                {cityData.airports.map((data, index) => (
                    <AirportBlock airportData={data} key={index} airportClickHandler={clickHandler} />
                ))}
                </>
            )}
            </div>
        </>
    );
}

function AirportBlock({ airportData, airportClickHandler }) {
    return (
        <>
            <div className="AirportSearchResultDiv" attr-code={ airportData.airport_code } attr-name={ '(' + airportData.airport_code + ') ' + airportData.airport_name } onClick={airportClickHandler}><img className="CountryFlag" src={ "img/flags/" + airportData.iso_country.toLowerCase() + ".png" } alt={ airportData.iso_country } />({ airportData.airport_code }) { airportData.airport_name }</div>
        </>
    );
}

export default CitySearchResult;