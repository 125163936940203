import { React, useContext } from 'react';
import { GlobalContext } from './App'; 

const FAQ = () => {
    const { siteUrl } = useContext(GlobalContext);
    
  return (
    <div>
        <div className="Header">
            <h1><a className="BlankLink" href={siteUrl}>Fake Flight Tickets 🛩️</a></h1>
        </div>
        <div className="MainContent">
            <div className="GetYourTicketDiv">
            Frequently Asked Questions
            </div>
            <div>
                <div className="QuestionDiv">What is Fake Flight Tickets?</div>
                <div className="AnswerDiv">
                    <p>Fake Flight Tickets is a service, helping you to solve the problem of presenting "proof of onward travel".</p>
                    <p>Numerous airlines and countries demand evidence of your intention to depart before permitting you to board a flight. Embracing flexibility and the uncertainty of your next destination is part of the joy of travel. However, committing hundreds of dollars and making concrete plans months in advance can be a costly and stressful process that curtails your freedom.</p>
                    <p>Recognizing the frustration associated with this, we've developed a dummy onward ticket generator. It allows you to navigate travel requirements without the financial and logistical constraints, empowering you to enjoy a more flexible and liberated travel experience.</p>
                </div>
                <div className="QuestionDiv">How does it work?</div>
                <div className="AnswerDiv">
                    <p>
                    We utilize data on actual flights worldwide and blend it with your personal information to create a booking 
                    confirmation document that closely mirrors an authentic one. The resemblance is so convincing that discerning between a 
                    legitimately issued flight confirmation and the one we generate becomes a challenging task, except through direct verification 
                    with the airline via a call.
                    </p>
                </div>
                <div className="QuestionDiv">Can the PDF ticket be verified online?</div>
                <div className="AnswerDiv">
                    <p>
                    Our PDFs are entirely fabricated and cannot undergo online verification. They are designed solely for convenience and should 
                    not be used for official or legal purposes.
                    </p>
                </div>
                <div className="QuestionDiv">What information in the ticket is real?</div>
                <div className="AnswerDiv">
                    <p>
                    We copy the look of real flight confirmations from popular booking sites, including genuine flight details like price, 
                    flight number, date, and layovers. But, it's essential to know the booking or itinerary number we provide is not real, though it 
                    unique.
                    </p>
                </div>
                <div className="QuestionDiv">What to do with Fake Flight Tickets ticket in airport?</div>
                <div className="AnswerDiv">
                    <p>
                    Depending on your destination and passport, you might be required to show an onward ticket during check-in, especially when 
                    flying to places like Bali, Indonesia or Thailand. While individual experiences may vary, here's a general overview of what 
                    typically occurs with me and what steps I usually take:
                    </p>
                    <p>
                        <ul>
                            <li>If asked, I provide my onward ticket to the check-in staff at the airport.</li>
                            <li>The check-in personnel typically only glance at the flight number, airline, and date on the onward ticket. 
                                They might enter the information of the flight route into their system to check.</li>
                            <li>In my experience, they don't seem to be concerned with the Itinerary number or the PNR confirmation number; their focus is primarily on 
                                verifying the flight details such as the flight number, airline, and date.</li>
                            <li>If questioned about the PNR confirmation number, a response could be: <i>"I just purchased this ticket a 
                                couple days ago, so it's possible that the actual ticket isn't issued yet."</i></li>
                        </ul>
                    </p>
                    <p>
                    Keep in mind - this is based on my flight experience. Yours might vary.
                    </p>
                </div>
                <div className="QuestionDiv">What is the price for the ticket?</div>
                <div className="AnswerDiv">
                    <p>
                    Currently we charge 5 euros for a ticket - as much as a cappuccino. 
                    </p>
                </div>
                <div className="QuestionDiv">Is the ticket real?</div>
                <div className="AnswerDiv">
                    <p>
                        It's important to note that the ticket confirmation you receive is not authentic. However, visually, it is designed to be 
                        indistinguishable from a genuine one.
                    </p>
                </div>
                <div className="QuestionDiv">Can I verify the ticket online?</div>
                <div className="AnswerDiv">
                    <p>
                        You can not verify this ticket.
                    </p>
                </div>
                <div className="QuestionDiv">Can I edit ticket data?</div>
                <div className="AnswerDiv">
                    <p>
                    It's crucial to provide accurate information during the process. In exceptional situations, we may assist with corrections, 
                    but please understand that this could take several days. Opting for a new and accurate purchase would likely be a faster solution.
                    </p>
                </div>
                <div className="QuestionDiv">Is it possible to get a refund?</div>
                <div className="AnswerDiv">
                    <p>
                    We do not offer refunds; our service operates on a straightforward principle. Additionally, we recognize the value of your 
                    time and believe it's more worthwhile than spending it on chasing a $5 refund.
                    </p>
                </div>
                <div className="QuestionDiv">What if I am denied boarding?</div>
                <div className="AnswerDiv">
                    <p>
                    Highly likely it's for another reason, other than your return ticket. Either way, keep in mind, we don't take responsability for that.
                    </p>
                </div>
                <div>
                    <p>Have a save flight!<br />Fake Flight Tickets</p>
                </div>
            </div>
        </div>
        <div className="Footer">
            <div className="InfoText">
                <i><u>Keep in mind</u></i>, this is not a real ticket - it just looks like one.
            </div>
            <a href={siteUrl}><u>Fake Flight Tickets</u></a>
            <a href={siteUrl + "/terms"}><u>Terms & Privacy Policy</u></a>
            <a href={siteUrl + "/faq"}><u>FAQ</u></a>
            <a href={"mailto:support@fakeflighttickets.net"}><u>Contact Us</u></a>
        </div>
    </div>
  );
};

export default FAQ;
