import React, { useState, useContext } from 'react';
import { GlobalContext } from '../App'; 

const SendTicket = ({ email, flight, tTitle, tFirstName, tLastName, tType, tEmail, finished, finishedSent }) => {
    const { beUrl } = useContext(GlobalContext);

    const [inputEmail, setInputEmail] = useState(tEmail);

    const handleOnChange = (event) => {
        setInputEmail(event.target.value);
    }

    const handleSendTicket = () => {
        finished();

        let tName = tFirstName + " " + tLastName

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "email" : tEmail, "ticket_data" : flight, "traveler_data": { "title": tTitle, "name": tName, "type": tType } })
        };
        fetch(beUrl + '/send-ticket', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.msg !== undefined && data.msg === "success") {
                    window.gtag('event', 'sent_email');
                    finishedSent();
                } else {
                    alert("Something went wrong, contact us please.")
                }
            });
    }

    return (
        <div className="SendTicketModal">
            <div className="SendTicketForm">
                <div className="SendTicketInfo">TicketMaster got your payment. If there was an E-mail attached to the request - you'll see it in the input below. Anyway - feel free to type the E-mail where you want the ticket sent.</div>
                <input type="text" className="EmailInput" value={ inputEmail } onChange={ handleOnChange } />
                <input type="submit" className="SendTicketBtn" value="Send" onClick={ handleSendTicket } />
            </div>
        </div>
    );
}

export default SendTicket;