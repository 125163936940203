import { GlobalContext } from '../App'; 
import { useContext } from 'react';
import { PayPalButtons } from "@paypal/react-paypal-js";

const PaypalCheckoutButton = ({ ticketPayment }) => {
    const { beUrl } = useContext(GlobalContext);

    const createOrder = async (data) => {
    const response = await fetch(beUrl + "/create-paypal-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                product: {
                    description: "Fake flight ticket",
                    cost: 4
                }
            }),
        });
        const order = await response.json();
        console.log(order);
        return order.id;
    };
    const onApprove = async (data) => {
        const response = await fetch(beUrl + "/capture-paypal-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                orderID: data.orderID
            })
        });
        const captureResponse = await response.json();
        console.log(captureResponse);
        ticketPayment(captureResponse);
        return captureResponse;
    };

    return (
        <div className="PayPalBtnsContainer">
            <PayPalButtons
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
            />
        </div>
    );
}

export default PaypalCheckoutButton;